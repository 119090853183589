import { useContext, useRef, useState, useEffect } from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';
import { Context } from './Context';
import './App.css';
import './firebase';

// Components
import Header from './components/Header';
import Loading from './components/Loading';
import ScrollToTop from './components/ScrollToTop';

// MUI UI components:
import { Box, CssBaseline, AppBar, Toolbar, IconButton, List, ListItem, ListItemIcon, ListItemText, Divider, } from '@mui/material';
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, QuestionAnswer as QuestionAnswerIcon, Assessment as AssessmentIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { useMediaQuery, SwipeableDrawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { X } from 'lucide-react';


// Icons
import {
  Home, Users, PlayCircle, Book, ClipboardCheck, TrendingUp,
  Dumbbell, UsersRound,
} from 'lucide-react';

// Pages
import Homepage from './pages/Homepage';
import Login from './pages/Login';
import LandingPage from './pages/LandingPage';
import Admin from './pages/Admin';
import Profile from './pages/Profile';
import SuperAdmin from './pages/SuperAdmin';
import General from './pages/General';
import YourProgram from './pages/YourProgram';
import Videos from './pages/Videos';
import Step1 from './pages/Onboarding/Step1';
import Step2 from './pages/Onboarding/Step2';
import Step3 from './pages/Onboarding/Step3';
import Attendance from './pages/Teachers/Attendance';
import Results from './pages/Teachers/Results';
import Extra from './pages/Teachers/Extra';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import NotFound from './pages/NotFound';

const drawerWidth = 260;

// Custom styled ListItem component
const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    '& .MuiListItemText-primary': {
      color: 'var(--primary)',
    },
  },
  '& .MuiListItemText-primary': {
    color: theme.palette.text.primary,
  },
  '&.Mui-selected': {
    color: 'var(--primary)',
    '&:hover': {
      color: 'var(--primary)',
    },
    '& .MuiListItemText-primary': {
      color: theme.palette.primary.main,
    },
  },
}));

// main content area styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginLeft: `-${drawerWidth}px`,
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

// custom styled AppBar component
const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      backgroundColor: 'white',
      boxShadow: 'none',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    ...(!open && {
      width: '100%',
      marginLeft: `${drawerWidth}px`,
      backgroundColor: 'white',
      boxShadow: 'none',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }
    )
  }),
);

function App() {
  const { userState, loading } = useContext(Context);
  const { role, tenantName } = userState;
  const location = useLocation();
  const [selectedPath, setSelectedPath] = useState(location.pathname); // for highlighting the selected path in the sidebar
  const drawerRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => { setMobileOpen(!mobileOpen); };

  const isKnownRoute = [
    "/", "/admin", "/profile",
    "/superadmin", "/general", "/yourprogram", "/videos",
    "/attendance", "/results", "/extra", "/privacy", "/terms"
  ].includes(location.pathname) || location.pathname.startsWith("/onboarding/");

  const shouldShowHeader = isKnownRoute;

  const openedMixin = (theme) => ({
    width: isMobile ? '100%' : drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: isMobile ? 0 : `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: isMobile ? 0 : `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  // Determine if the current path is the login page or the profile page
  const isLoginPage = location.pathname === '/login';
  const isProfilePage = location.pathname.includes('/profile');
  const isLandingPage = location.pathname === '/landing';

  // sidebar state
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => { setOpen(true); };
  const handleDrawerClose = () => { setOpen(false); };
  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      handleDrawerClose();
    }
  };

  // side menu data
  const menuItemsStudents = [
    { text: 'Jouw dashboard', icon: <Home />, link: '/' },
    { text: 'Jouw groep', icon: <Users />, link: '/yourprogram' },
    { text: 'E-leren', icon: <PlayCircle />, link: '/videos' },
    { text: 'Over uw programma', icon: <Book />, link: '/general' },
    ...(tenantName === "CULTUUR & KUNST" ? [{ text: 'Extra oefeningen', icon: <Dumbbell />, link: '/extra' }] : [])
  ];

  const menuItemsTeachers = [
    { text: 'Aanwezigheid', icon: <ClipboardCheck />, link: '/attendance' },
    { text: 'Studenten resultaten', icon: <TrendingUp />, link: '/results' },
    ...(tenantName !== "CULTUUR & KUNST" ? [{ text: 'Extra oefeningen', icon: <Dumbbell />, link: '/extra' }] : [])
  ];

  const adminMenuItems = [
    { text: 'Admin Panel', icon: <UsersRound />, link: '/admin' },
    // { text: 'Manage Programs', icon: <Award />, link: '/admin' },
    // { text: 'Manage Quizzes', icon: <CheckSquare />, link: '/admin' },
    // { text: 'Anayltics', icon: <BarChart2 />, link: '/admin' },
  ];

  const sidebar = (
    <div style={{
      paddingTop: isMobile ? '10px' : '',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <List>
        {menuItemsStudents.map((item) => (
          <StyledListItem button key={item.text} component={Link} to={item.link}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </StyledListItem>
        ))}
      </List>
      <Divider />

      {(role === 'teacher' || role === 'admin') && (
        <>
          <List>
            {menuItemsTeachers.map((item) => (
              <StyledListItem button key={item.text} component={Link} to={item.link}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </StyledListItem>
            ))}
          </List>
          <Divider />
        </>
      )}

      {role === 'admin' && (
        <List>
          {adminMenuItems.map((item) => (
            <StyledListItem button key={item.text} component={Link} to={item.link}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          ))}
        </List>
      )}
    </div>
  );

  // close sidebar when clicking outside
  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    zIndex: theme.zIndex.appBar - 1,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position: 'fixed', // Add this line to make it overlay on top
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
  );

  if (loading) return <Loading />;

  return (
    // side menu:
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {!(isLoginPage || isProfilePage || isLandingPage) && (
        <>
          <AppBarStyled position="fixed" open={!isMobile && open}
          >
            <Toolbar sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  color="inherit"
                  className='transparentBtn'
                  aria-label="open drawer"
                  edge="start"
                  onClick={isMobile ? handleDrawerToggle : handleDrawerOpen}
                  sx={{ paddingLeft: mobileOpen ? '12px' : '5px' }}
                >
                  {isMobile && mobileOpen ? <X style={{ color: '#555' }} /> : <MenuIcon style={{ color: '#555', }} />}
                </IconButton>
              </div>
              <Header location={location} />
            </Toolbar>
          </AppBarStyled>
          {!isMobile && (
            <Drawer ref={drawerRef} variant="permanent" open={open} className="MuiDrawer-root">
              <DrawerHeader style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 20px' }}>
                {open &&
                  <div className='nav-container' >
                    <Link to="/">
                      {open ? '' : <MenuIcon style={{ color: '#555' }} />}
                    </Link>
                  </div>
                }
                <IconButton onClick={handleDrawerClose} >
                  {open && <ChevronLeftIcon sx={{
                    '&:hover': {
                      borderRadius: '10%',
                      backgroundColor: 'var(--light-grey)',
                      color: 'var( --primary) !important',
                    },
                  }} />}
                </IconButton>
              </DrawerHeader>
              {sidebar}
            </Drawer>
          )}
          {isMobile && (
            <SwipeableDrawer
              swipeAreaWidth={0}
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              onOpen={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Box sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '56px', // Add top padding to account for AppBar height
              }}>
                {sidebar}
              </Box>
            </SwipeableDrawer>
          )}

        </>
      )}
      <Main open={!isMobile && open}

      >
        {/* {!(isLoginPage || isProfilePage) && <DrawerHeader />} */}
        <ScrollToTop />
        {shouldShowHeader && <Header />}
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/profile/*" element={<Profile />} />
          <Route path="/superadmin" element={<SuperAdmin />} />
          <Route path="/onboarding/step1" element={<Step1 />} />
          <Route path="/onboarding/step2" element={<Step2 />} />
          <Route path="/onboarding/step3" element={<Step3 />} />
          <Route path="/general" element={<General />} />
          <Route path="/yourprogram" element={<YourProgram />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/attendance" element={<Attendance />} />
          <Route path="/results" element={<Results />} />
          <Route path="/extra" element={<Extra />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Main>
    </Box>
  );

}

export default App;