import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from 'react';
import { Context } from "../../Context";
import { storage } from '../../firebase';
import { ref, getDownloadURL, listAll, } from "firebase/storage";
import { db } from '../../firebase';
import { collection, getDocs } from "firebase/firestore";
import Loading from "../../components/Loading";
import withAuthentication from "../../withAuthentication";
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// UI + images
import { TextField, MenuItem } from "@mui/material";
import ckBGDesktop from '../../images/ckBGDesktop.png';
import ckBGTablet from '../../images/ckBGTablet.png';
import ckBGMobile from '../../images/ckBGMobile.png';
import useScreenSize from '../../components/useScreenSize';
import lightbulb from '../../images/lightbulb.png';
import star from '../../images/star.png';
import wavyLine from '../../images/wavyLine.png';

function Extra() {
    const { userState, loading } = useContext(Context);
    const { uid, tenantId, program, role, tenantName } = userState;

    const [pdfs, setPdfs] = useState([]);
    const [allProgramsDropdown, setAllProgramsDropdown] = useState([]);
    const [programsData, setProgramsData] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState('');
    const [disableMultiPrograms, setDisableMultiPrograms] = useState(false);

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    const navigate = useNavigate();
    const isCK = tenantName === "CULTUUR & KUNST" || (program && program[0] === 'MDT Cultuur & Kunst')
    const screenSize = useScreenSize(); // 'mobile', 'tablet', or 'desktop'

    const getBackgroundImage = () => {
        switch (screenSize) {
            case 'mobile':
                return ckBGMobile;
            case 'tablet':
                return ckBGTablet;
            default:
                return ckBGDesktop;
        }
    };
    const ckBackgroundStyle = {
        backgroundImage: `url(${getBackgroundImage()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }

    useEffect(() => {
        if (tenantName !== 'Tino') {
            setDisableMultiPrograms(true);
        }
    }, [tenantName])

    // fetch programs from firestore
    useEffect(() => {
        if (loading || !tenantId || !role || !program) return;

        const fetchAllPrograms = async () => {
            const programsRef = collection(db, 'tenants', tenantId, 'programs');
            try {
                const programSnapshot = await getDocs(programsRef);
                if (programSnapshot.empty) {
                    console.log('No matching documents.');
                    return;
                }
                const allProgramsFromDb = programSnapshot.docs.map(doc => {
                    const programData = doc.data();
                    return {
                        uid: doc.id,
                        ...programData
                    };
                });
                // all programs from firestore
                setProgramsData(allProgramsFromDb);
                // if set selected program to the first program in the array
                setSelectedProgram(allProgramsFromDb.find(programFromDb => programFromDb.name === program[0]));

            } catch (err) {
                console.error("Error fetching programs data:", err);
            }
        };
        fetchAllPrograms();

    }, [loading, tenantId]);

    // set all programs to a dropdown:
    useEffect(() => {
        if (loading || !programsData) return;
        // check if programsData is an array, if not, exit
        if (!Array.isArray(programsData)) return;
        const allProgramsDropdown = programsData.map((program, index) => {
            return (
                <MenuItem key={index} value={program.name}>
                    {program.name}
                </MenuItem>
            );
        });
        setAllProgramsDropdown(allProgramsDropdown);

    }, [loading, programsData, role]);

    // fetch pdfs from firebase storage
    useEffect(() => {
        if (loading || !uid || !selectedProgram) return;
        const fetchPdfs = async () => {
            try {
                const programName = selectedProgram.name;
                const pdfsRef = ref(storage, `${tenantId}/extra/${programName}`);
                const pdfs = await listAll(pdfsRef)
                const pdfsArray = await Promise.all(
                    pdfs.items.map(async (itemRef) => {
                        // for each item, get the download url:
                        const url = await getDownloadURL(itemRef);
                        const pdfsObject = {
                            name: itemRef.name,
                            url: url,
                        }
                        return pdfsObject;
                    })
                )
                setPdfs(pdfsArray);
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchPdfs();
    }, [loading, uid, tenantId, selectedProgram])

    if (loading) return <Loading />

    return (
        <div className={`page-container ${isCK ? 'bg-overlay' : ''}`}
            style={isCK ? ckBackgroundStyle : {}}>
            <div className='page-title'>
                <h3 style={{ color: isCK ? 'white' : '' }}>Extra oefeningen</h3>
                <h5 style={{ color: isCK ? 'white' : '' }}>Open een van de PDF's om ze te bekijken, te downloaden of af te drukken</h5>
                {/* {!isCK && screenSize === 'desktop' &&
                    <div className="sweater-girl-container">
                        <img
                            src={star ? star : ''}
                            style={{ top: '15%', width: '100px', left: '100px', transform: 'rotate(340deg)' }}
                            alt="teacher"
                            className="sweater-girl-img"
                        />
                        <img
                            src={wavyLine ? wavyLine : ''}
                            style={{ top: '10%', width: '120px', right: '50px', transform: 'rotate(20deg)' }}
                            alt="teacher"
                            className="sweater-girl-img"
                        />

                    </div>
                } */}

            </div>

            <div className=' extra-page'>
                {role === 'admin' && programsData && programsData.length > 1 &&
                    <>
                        <p style={{ color: isCK ? 'white' : 'var(--global-black)' }}>
                            Kies een programma om de extra oefeningen te bekijken
                        </p>
                        <TextField
                            id="outlined-select-currency-native"
                            select
                            sx={{ borderRadius: '10px', width: '20%', margin: '5px 0px 25px', backgroundColor: isCK ? 'white' : '' }}
                            value={selectedProgram?.name || ''}
                            placeholder="Kies een programma"
                            onChange={(e) => {
                                const selectedProgramData = programsData.find(program => program.name === e.target.value);
                                setSelectedProgram(selectedProgramData);
                            }}
                        >
                            {allProgramsDropdown}
                        </TextField>
                    </>
                }

                {pdfs && pdfs.length > 0 ?
                    <div className="magazines-container" >
                        {pdfs && pdfs.map((pdf, index) => (
                            <div key={index} className="magazine-box" >
                                <div className=" magazine-title">
                                    <p>{pdf.name}</p>
                                </div>
                                <div className="magazine-body">
                                    <Document file={pdf.url}
                                        loading={
                                            <div style={{ marginTop: '45px' }}>
                                                <i className="fas fa-spinner fa-spin fa-xl"></i>
                                            </div>} >
                                        <Page
                                            pageNumber={1}
                                            width={250}
                                        />
                                    </Document>
                                </div>
                                <div className="magazine-box-btns">
                                    <a href={pdf.url} download={pdf.name} target="_blank" rel="noreferrer">
                                        <button>PDF Openen</button>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <div className="magazines-container" >
                        <h3 style={{ color: isCK ? 'white' : 'var(--global-black)' }}>Geen PDF's beschikbaar</h3>
                    </div>
                }

                <div className="pagination">
                    <button onClick={() => navigate(-1)}>
                        <i className="fas fa-arrow-left"></i>
                        Terug
                    </button>

                </div>
            </div>
        </div>

    )
}
export default withAuthentication(Extra, false, false);