import { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from '../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Context } from "../Context";
import Loading from "../components/Loading";

function Login() {
    const { setUserState, loading } = useContext(Context);

    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({ email: '', password: '', });
    const [messgae, setMessage] = useState('');

    const redirectUrl = new URLSearchParams(location.search).get("redirectTo") || '/';

    const validatePassword = () => {
        let isValid = true
        const { password, email } = formData
        if (password === '' || email === '') {
            isValid = false
            setMessage('Please enter a valid email and password')
        }
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        // Trim the inputs
        const trimmedEmail = email.trim();
        const trimmedPassword = password.trim();

        // Check if the email is valid
        if (!emailRegex.test(trimmedEmail)) {
            isValid = false
            setMessage('Please enter a valid email')
        }

        // Check if the password is valid
        if (!passwordRegex.test(trimmedPassword)) {
            isValid = false
            setMessage('Password must contain at least 8 characters, including 1 letter and 1 number.')
        }

        return isValid
    }

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            const { email } = formData
            await sendPasswordResetEmail(auth, email);
            setMessage('Password reset email sent. Please check your email and follow the instructions.')
        } catch (error) {
            console.log('Error sending password reset email: ', error.message);
            setMessage(error.message)
        }
    }

    function handleChange(e) {
        setMessage('')
        const { id, value } = e.target;
        setFormData(prevState => ({ ...prevState, [id]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('')

        if (validatePassword()) {
            try {
                const { email, password } = formData
                const { user } = await signInWithEmailAndPassword(auth, email, password);

                setUserState(prevState => ({ ...prevState, uid: user.uid, }));
                // localStorage.setItem("uid", JSON.stringify(user.uid));
                navigate(redirectUrl);

            } catch (err) {
                if (err.message === 'Firebase: Error (auth/user-not-found).') {
                    setMessage('Email not found. Please check your email and try again')
                } else if (err.message === 'Firebase: Error (auth/wrong-password).') {
                    setMessage('Incorrect password. Please try again')
                } else if (err.message === 'Firebase: Error (auth/too-many-requests).') {
                    setMessage('Too many login attempts. Please try again later')
                } else if (err.message === 'Firebase: Error (auth/invalid-email).') {
                    setMessage('Invalid email. Please try again')
                } else if (err.message === 'Firebase: Error (auth/invalid-password).') {
                    setMessage('Invalid password. Please try again')
                } else if (err.message === 'Firebase: Error (auth/user-disabled).') {
                    setMessage('User disabled. Please contact your administrator')
                } else if (err.message === 'Firebase: Error (auth/invalid-login-credentials).') {
                    setMessage('Invalid login credentials. Please try again')
                } else {
                    setMessage('Error logging in. Please try again')
                }
                console.log("err.message:", err.message);
            }
        } else {
            console.log('cant submit form')
            setMessage('Password or email do not match. Please try again.')
            setFormData({
                email: '',
                password: '',
            })
        }
    }

    if (loading) return <Loading />
    return (

        <div className="login-main-container" data-testid='login-main'>

            <div className="login-form-container">
                <div className="login-form-title">
                    <h2 >MDT PORTAAL</h2>
                    <h3 >Log in</h3>

                </div>
                <form className="login-form" onSubmit={handleSubmit}>
                    <p >Vul de inloggegevens in die je hebt ontvangen</p>
                    <input
                        type="email"
                        id="email"
                        data-testid='email'
                        placeholder="E-mail"
                        value={formData.email || ''}
                        onChange={handleChange}
                    />
                    <input
                        type="password"
                        id="password"
                        data-testid='password'
                        placeholder="Wachtwoord"
                        value={formData.password || ''}
                        onChange={handleChange}
                    />
                    <button
                        style={{ width: '100%' }}
                        type="submit"
                        data-testid='login'>Log in
                    </button>
                </form>
                {messgae && <p className="successMessage">{messgae}</p>}
                <div className="signin-help" >
                    <button
                        className="transparentBtn"
                        onClick={handleResetPassword}>
                        Wachtwoord vergeten
                    </button>
                    <a
                        href={`mailto:info@tinomdt.nl`}
                        rel="noopener noreferrer">
                        Problemen met inloggen
                    </a>
                </div>

            </div>


            <div className="pagination">
                <a
                    href="/landing"
                    rel="noopener noreferrer"
                    style={{ color: 'var(--black-logo)', textDecoration: 'underline', padding: '20px' }}
                >Back
                </a>
            </div>

        </div>
    );


}

export default Login;