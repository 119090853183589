import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from 'react';
import { Context } from "../Context";
import { db } from '../firebase';
import { doc, onSnapshot, collection, getDocs } from "firebase/firestore";
import withAuthentication from "../withAuthentication";
import useScreenSize from '../components/useScreenSize';

// UI
import Loading from "../components/Loading";
import { Tooltip } from 'react-tooltip';
import { MenuItem, Select, Accordion, AccordionSummary, AccordionDetails, Typography, CardContent, Card } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ckBGDesktop from '../images/ckBGDesktop.png';
import ckBGTablet from '../images/ckBGTablet.png';
import ckBGMobile from '../images/ckBGMobile.png';

function YourProgram() {
    // conect to Context for global state
    const { userState, loading, updateData } = useContext(Context);
    const { program, firstName, lastName, group, email, uid, role, tenantId, tenantName } = userState;

    // local state
    const [userGroupData, setUserGroupData] = useState('');
    const [userProgramData, setUserProgramData] = useState('')
    const [selectedProgram, setSelectedProgram] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedGroupData, setSelectedGroupData] = useState('');
    const [completedQuestionnairesArray, setCompletedQuestionnairesArray] = useState([]);
    const [groupsDropdown, setGroupsDropdown] = useState([])
    const [programsDropdown, setProgramsDropdown] = useState([])

    // UI
    const screenSize = useScreenSize(); // 'mobile', 'tablet', or 'desktop'
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const navigate = useNavigate();
    const isCK = tenantName === "CULTUUR & KUNST" || (program && program[0] === 'MDT Cultuur & Kunst')

    const getBackgroundImage = () => {
        switch (screenSize) {
            case 'mobile':
                return ckBGMobile;
            case 'tablet':
                return ckBGTablet;
            default:
                return ckBGDesktop;
        }
    };
    const ckBackgroundStyle = {
        backgroundImage: `url(${getBackgroundImage()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }

    // change the state of isMobile when the window is resized:
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // search the programs collection and for each match return the program data:
    useEffect(() => {
        if (loading || !program || !tenantId) return
        const fetchPrograms = async () => {
            try {
                const programsRef = collection(db, 'tenants', tenantId, 'programs');
                // return all programs
                const programSnapshot = await getDocs(programsRef);
                if (programSnapshot.empty) {
                    // console.log('No matching documents.');
                    return;
                }
                // map through the programs and return the data for the programs in the program array in the userState:
                const programsData = programSnapshot.docs.map(doc => {
                    const data = {
                        id: doc.id,
                        ...doc.data()
                    }
                    return data
                });

                setUserProgramData(programsData)
                // if user is 'user' it means they only have 1 program and it already needs to be selected:
                const userProgram = program[0]
                const userProgramData = programsData.find(program => program.name === userProgram || program.id === userProgram)
                setSelectedProgram(userProgramData)

            } catch (error) {
                console.log("Error getting documents: ", error);
            }
        }
        fetchPrograms();
    }, [loading, program, tenantId, role]);

    // from userProgramData prepare the dropdown for the programs:
    useEffect(() => {
        if (loading || !userProgramData || !tenantId) return
        const programsDropdown = userProgramData.map((program) => {
            return <MenuItem key={program.id} value={program.name}>
                {program.name}
            </MenuItem>
        })
        setProgramsDropdown(programsDropdown)
    }, [loading, userProgramData, tenantId]);

    // prepare group data
    useEffect(() => {
        if (loading || !group || !tenantId) return
        const fetchGroups = async () => {
            try {
                const groupsRef = collection(db, 'tenants', tenantId, 'groups');
                const groupsSnapshot = await getDocs(groupsRef);
                const groupsData = groupsSnapshot.docs.map(doc => {
                    const data = {
                        id: doc.id,
                        ...doc.data()
                    }
                    return data
                }
                );

                // if user is 'user' it means they only have 1 group and it already needs to be selected:
                const userGroup = group[0]
                const groupData = groupsData.find(group => group.name === userGroup)
                setSelectedGroupData(groupData)
                setSelectedGroup(userGroup)
                setUserGroupData(groupsData)

            } catch (error) {
                console.log("Error getting documents: ", error);
            }
        }
        fetchGroups();
    }, [loading, group, tenantId, role]);

    // from userGroupData prepare the dropdown for the programs:
    useEffect(() => {
        if (loading || !userGroupData || !tenantId || role === 'user' || !group) return
        // if teacher, only show the groups that are in the group array in the userState:

        // extract the group info from userGroupData that matches the group array in the userState:
        const userGroupsArray = group
        const teacherGroupsData = userGroupData.filter(group => userGroupsArray.includes(group.name))

        const groupsDropdown = teacherGroupsData.map((group, index) => {
            return <MenuItem key={index} value={group.name}>
                {group.name}
            </MenuItem>
        })
        setGroupsDropdown(groupsDropdown)
        return


    }, [loading, userProgramData, tenantId, userGroupData, role, group]);

    // fetch the completed questionnaires from the user collection in the db:
    useEffect(() => {
        if (loading || !userState || !tenantId) return

        // fetch questionnaires from db in the users collection and update the questionnairesArray with the data:
        const fetchQuestionnaires = async () => {
            try {
                const userRef = doc(db, 'tenants', tenantId, 'users', uid);

                // Listen for real-time updates using onSnapshot
                const unsubscribe = onSnapshot(userRef, (userSnap) => {
                    if (!userSnap.exists()) return
                    const userData = userSnap.data();
                    if (!userData) return

                    // completed questionnaires:
                    const userQuestionnaires = userData.questionnaires

                    // if there are no completed questionnaires, return:
                    if (!userQuestionnaires || userQuestionnaires.length === 0) return

                    // if there are questionnaires, update the questionnairesArray with the data:
                    setCompletedQuestionnairesArray(userQuestionnaires)
                })
                return () => unsubscribe();
            } catch (error) {
                console.log("Error getting document:", error);
            }
        }
        fetchQuestionnaires();

    }, [loading, userState, uid, tenantId]);

    const handleProgramSelection = (e) => {
        const selectedProgramName = e.target.value
        const programData = userProgramData.find(program => program.name === selectedProgramName)
        setSelectedProgram(programData)
    }
    const handleGroupSelection = (e) => {
        const selectedGroupName = e.target.value
        setSelectedGroup(selectedGroupName)
        const groupData = userGroupData.find(group => group.name === selectedGroupName)
        setSelectedGroupData(groupData)
    }

    const handleMoveToVideoPage = async (session) => {
        // set the session data in the global state:
        try {
            await updateData('session', session);
            navigate('/videos')
        } catch (error) {
            console.log('error', error)
        }
    }


    if (loading) return <Loading />

    return (
        <div className={`page-container ${isCK ? 'bg-overlay' : ''}`}
            style={isCK ? ckBackgroundStyle : {}}>
            <div className='page-title'>
                <h3 style={{ color: isCK ? 'white' : '' }}>{selectedProgram ? selectedProgram.name : ''} Programmaschema</h3>
                <h5 style={{ color: isCK ? 'white' : '' }}>Hoe ziet jouw {selectedProgram ? selectedProgram.name : ''} eruit?</h5>
            </div>
            <div className='program-main-container' >
                <div className="program-selection-section" >
                    {role && role === 'admin' &&
                        <div className="program-selection-container">
                            {Array.isArray(program) && program.length > 1 &&
                                <div className="program-selection-box" >
                                    <h6 style={{ color: isCK ? 'white' : '' }}>Selecteer het programma</h6>
                                    <Select
                                        disabled={!Array.isArray(program)}
                                        value={selectedProgram ? selectedProgram.name : ''}
                                        label="Selected Program"
                                        style={{ width: '220px', marginTop: '5px', backgroundColor: isCK ? 'white' : '' }}
                                        displayEmpty
                                        renderValue={(value) => {
                                            if (value === '') {
                                                return <p>Selecteer programma</p>;
                                            }
                                            return value;
                                        }}
                                        onChange={(e) => handleProgramSelection(e)}
                                    >
                                        {programsDropdown !== '' && programsDropdown}
                                    </Select>
                                </div>
                            }
                        </div>
                    }
                    {role && (role === 'teacher' || role === 'admin') &&
                        <div className="program-selection-container" style={{ marginBottom: isMobile ? '50px' : '' }}>
                            {Array.isArray(group) && group.length > 1 &&
                                <div className="program-selection-box" >

                                    <h6 style={{ color: isCK ? 'white' : '' }}>Selecteer de groep</h6>
                                    <Select
                                        disabled={!Array.isArray(group)}
                                        value={selectedGroup ? selectedGroup : ''}
                                        label="Selected Group"
                                        style={{ width: '220px', marginTop: '5px', backgroundColor: isCK ? 'white' : '' }}
                                        displayEmpty
                                        renderValue={(value) => {
                                            if (value === '') {
                                                return <p>Selecteer groep</p>;
                                            }
                                            return value;
                                        }}
                                        onChange={(e) => handleGroupSelection(e)}
                                    >
                                        {groupsDropdown !== '' && groupsDropdown}
                                    </Select>
                                </div>
                            }
                        </div>
                    }
                </div>

                <div className="element-box 2">
                    {/* {!isCK && screenSize === 'desktop' &&
                        <div className="sweater-girl-container">
                            <img src={sweaterGirl || ''}
                                alt='sweater-girl'
                                className="sweater-girl-img"

                            />
                        </div>
                    } */}

                    <div className="program-table-container program-table element-body" style={{ display: isMobile ? '' : 'flex', maxHeight: isMobile ? '' : '90vh' }} >
                        {isMobile ? (
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                {selectedGroupData && selectedGroupData.schedule && selectedGroupData.schedule.map((session, index) => {
                                    return (
                                        <Accordion key={index} sx={{ width: '100%', margin: '5px', boxShadow: 'none', }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.6rem', color: 'var(--primary)' }} />}
                                                aria-controls="session-content"
                                                id="session-header"
                                                sx={{
                                                    padding: '0rem 0.5rem',
                                                    '&:not(:last-child)': {
                                                        borderBottom: 0,
                                                    },
                                                    '&:before': {
                                                        display: 'none',

                                                    }

                                                }}
                                            ><Typography sx={{ fontWeight: '500', fontSize: '0.95rem', flexGrow: 1 }}>Sessie {index + 1}: {session.date}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ border: '0.5px solid #b9b9b9', borderRadius: '4px', padding: '5px' }}>
                                                <Typography style={{ padding: '5px 0px', fontSize: '0.95rem' }}>
                                                    <i className="fas fa-clipboard-list" style={{ marginRight: '15px' }} ></i>
                                                    {session?.topic}

                                                </Typography>
                                                <Typography style={{ padding: '5px 0px', fontSize: '0.95rem' }}>
                                                    <i className="fas fa-calendar-day" style={{ marginRight: '15px' }} ></i>
                                                    {session?.date}

                                                </Typography>
                                                <Typography style={{ padding: '5px 0px', fontSize: '0.95rem' }}>
                                                    <i className="fas fa-clock" style={{ marginRight: '15px' }} ></i>
                                                    {session?.time}

                                                </Typography>
                                                <Typography style={{ padding: '5px 0px', fontSize: '0.95rem' }}>
                                                    <i className="fas fa-map-marker-alt" style={{ marginRight: '15px' }} ></i>
                                                    {session?.location}

                                                </Typography>
                                                <Typography style={{ padding: '5px 0px', fontSize: '0.95rem' }}>
                                                    <i className="fas fa-sticky-note" style={{ marginRight: '15px' }} ></i>
                                                    {session?.notes}
                                                </Typography>
                                                <Typography style={{ padding: '5px 0px', fontSize: '0.95rem' }}>
                                                    <i className="fas fa-link fa-sm" style={{ marginRight: '10px' }} ></i>
                                                    {session?.questionnaire && selectedProgram && selectedProgram.questionnaires ?
                                                        selectedProgram.questionnaires.some(item => item.title === session?.questionnaire) ?
                                                            <a href={`${selectedProgram.questionnaires.find(item => item.title === session?.questionnaire).url}?eml=${encodeURIComponent(email)}&fn=${firstName}&lsnm=${lastName}`}
                                                                target="_blank"
                                                                rel="noreferrer">
                                                                <button
                                                                    disabled={completedQuestionnairesArray.some(item => item.title === session?.questionnaire && item.complete)}
                                                                    style={{ fontSize: '0.9rem', padding: '8px', margin: '0px' }} className="btn-green">
                                                                    Vragenlijst beantwoorden</button>
                                                            </a> : 'Geen vragenlijst'
                                                        : 'Geen vragenlijst'
                                                    }
                                                    {session?.questionnaire && completedQuestionnairesArray.some(item => item.title === session?.questionnaire && item.complete) ?
                                                        <i className="fas fa-solid fa-check fa-ml" style={{ color: '#2aa81b', marginLeft: '5px' }}></i>
                                                        : ''}
                                                </Typography>
                                                <Typography style={{ padding: '5px 0px', fontSize: '0.95rem' }}>
                                                    <i className="fas fa-video" style={{ marginRight: '10px' }} ></i>
                                                    {session?.videos && session.videos.length > 0 &&
                                                        selectedProgram && selectedProgram.videos ?
                                                        <button onClick={() => handleMoveToVideoPage(session)} style={{ fontSize: '0.9rem', padding: '8px', margin: '0px' }} >{isCK ? 'Open' : 'Doe activiteiten'}</button>

                                                        : 'Geen activiteiten'}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                }
                                )}
                            </div>
                        ) : (
                            // desktop view
                            <table className="program-schedule-table">
                                <thead>
                                    <tr>
                                        <th>Sessie</th>
                                        <th>Onderwerp</th>
                                        <th>Datum</th>
                                        <th>Begintijd</th>
                                        <th>Eindtijd</th>
                                        <th>Plaats</th>
                                        <th>Opmerkingen</th>
                                        <th>Link naar vragenlijst</th>
                                        <th style={{ minWidth: '140px' }}>
                                            {isCK ? 'E-learning' : 'Activiteiten'}
                                        </th>
                                        <th >
                                            Vragenlijst ingevuld?
                                            <i
                                                data-tooltip-id="questionnaire"
                                                data-tooltip-content="Vul de vragenlijst in om je voortgang te meten"
                                                aria-hidden="true"
                                                style={{ marginLeft: '5px' }}
                                                className="fas fa-info-circle fa-ml">
                                            </i>
                                        </th>
                                        <Tooltip
                                            className="tooltip tooltip-questionnaire"
                                            id="questionnaire"
                                            place="bottom"
                                        />
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedGroupData && selectedGroupData.schedule && selectedGroupData.schedule.map((session, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{session.topic}</td>
                                                <td style={{ minWidth: '120px' }}>{session.date}</td>
                                                <td>{session.time}</td>
                                                <td>{session.endTime}</td>
                                                <td>{session.location}</td>
                                                <td>{session.notes}</td>
                                                <td >{session.questionnaire &&
                                                    selectedProgram && selectedProgram.questionnaires &&
                                                    selectedProgram.questionnaires.some(item => item.title === session.questionnaire) ?
                                                    <a href={`${selectedProgram.questionnaires.find(item => item.title === session.questionnaire).url}?eml=${encodeURIComponent(email)}&fn=${firstName}&lsnm=${lastName}`}
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        <button
                                                            disabled={completedQuestionnairesArray.some(item => item.title === session.questionnaire && item.complete)}
                                                            style={{ padding: '5px', margin: '5px' }}
                                                            className="btn btn-green">
                                                            Open</button></a> :
                                                    <p>-</p>}</td>
                                                <td>{session?.videos && session.videos.length > 0 ?
                                                    <button onClick={() => handleMoveToVideoPage(session)}
                                                        className="btn btn-green" style={{ padding: '5px', margin: '5px' }}> {isCK ? 'Open' : 'Doe activiteiten'}
                                                    </button> : '-'}</td>
                                                <td>{session?.questionnaire ?
                                                    selectedProgram && selectedProgram.questionnaires && selectedProgram.questionnaires.some(item => item.title === session.questionnaire) &&
                                                        completedQuestionnairesArray.some(item => item.title === session.questionnaire && item.complete) ?
                                                        <i className="fa-solid fa-check fa-ml" style={{ color: '#2aa81b' }}></i>
                                                        : <i className="fa-solid fa-x fa-ml" ></i>
                                                    : '-'}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            {/* <div className="pagination">
                <button onClick={() => navigate(-1)}>
                    <i className="fas fa-arrow-left"></i>
                    Terug
                </button>
            </div> */}
        </div>

    )
}
export default withAuthentication(YourProgram);